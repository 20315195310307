var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gnb_mobile_header"},[_c('img',{attrs:{"src":require("@/assets/pagenate-prev-arrow-icon-inactive_1.svg"),"alt":"","height":"40px"},on:{"click":function($event){return _vm.handleNavigation(_vm.e, false)}}}),_c('div',{staticClass:"gnb_mobile_menu_wrap"},_vm._l((_vm.menus),function(menu,index){return _c('a',{key:index,staticClass:"gnb_mobile_menu",style:([
        menu.title === 'DAO'
          ? { minWidth: '85px' }
          : menu.title === 'Tokamak Network'
            ? { minWidth: '160px' }
            : menu.title === 'Staking'
              ? { minWidth: '95px' }
              : menu.title === 'L2 Mainnet'
                ? { minWidth: '120px' }
                : menu.title === 'L2 Mainnet'
                  ? { minWidth: '120px' }
                  : menu.title === 'Bridge & Swap'
                    ? { minWidth: '142px' } : menu.title === 'Launchpad'? '130px'
                      : {},
        menu.isFoucsed ? { fontWeight: 600 } : {},
        menu.isFoucsed ? { opacity: 1 } : { opacity: 0.25 },
        index === _vm.menus.length - 1 ? { marginRight: '31%' } : {},
        index === 0 ? { marginLeft: (((_vm.deviceWidth - 80 - 378) / 2) + "px") } : {} ]),attrs:{"href":menu.url},on:{"touchstart":function (e) { return _vm.catchTouchStart(e); },"touchend":function (e) { return _vm.handleNavigation(e); }}},[_vm._v(" "+_vm._s(menu.title)+" ")])}),0),_c('img',{attrs:{"src":require("@/assets/pagenate-prev-arrow-icon-inactive_2.svg"),"alt":"","width":"40px","height":"40px"},on:{"click":function($event){return _vm.handleNavigation(_vm.e, true)}}})])}
var staticRenderFns = []

export { render, staticRenderFns }